import { useState } from "react";
import ViewTree from "../ViewTree/ViewTree";

const FolderStructureModel = ({ item, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <ul>
            <li>
                {item?.folderName ? (
                    <ViewTree
                        toggle={isOpen}
                        setToggle={setIsOpen}
                        folderName={item?.folderName}
                        isExpand={item?.isExpand}
                    />
                ) : null}

                {isOpen ? children : null}
            </li>
        </ul>
    );
};

export default FolderStructureModel;
