import { createContext, useContext, useSyncExternalStore } from "react";

export const FileStoreContext = createContext(null);

export const useFileStore = (selector) => {
    const store = useContext(FileStoreContext);
    if (!store) {
        throw "Error";
    }

    const state = useSyncExternalStore(store.subscribe, () =>
        selector(store.get())
    );
    return [state, store.set];
};
